import { get, post, postForm } from "./base.js";

/**
 * @param {any} data
 */
export function postBetOrder(data) {
    return post("/user/bet/order", data)
}

/**
 * @param {any} params
 */
export function getBetDetail(params) {
    return get("/user/bet/detail", params)
}

/**
 * @param {any} params
 */
export function getBetList(params) {
    return get("/user/bet/list", params)
}


/**
 * @param {any} data
 */
export function postBetRemark(data) {
    return post("/user/bet/remark", data)
}


/**
 * @param {any} params
 */
export function getActionList(params) {
    return get("/user/action/list", params)
}

/**
 * @param {any} params
 */
export function getUser(params) {
    return get("/user/detail", params)
}

/**
 * @param {any} data
 */
export function login(data) {
    return post("/user/login", data)
}

/**
 * @param {any} data
 */
export function register(data) {
    return post("/user/register", data)
}

/**
 * @param {any} data
 */
export function logout(data) {
    return post("/user/logout", data)
}

/**
 * @param {any} params
 */
export function getUserAgentSum(params) {
    return get("/user/agent/sum", params)
}


/**
 * @param {any} params
 */
export function getAgentUserList(params) {
    return get("/user/agent/user/list", params)
}


/**
 * @param {any} params
 */
export function getUserShareLink(params) {
    return get("/user/sharelink", params)
}

/**
 * @param {any} params
 */
export function getPhoneCaptcha(params) {
    return get("/user/phone/captcha", params)
}

/**
 * @param {any} params
 */
export function getUserAgentStatic(params) {
    return get("/user/agent/static", params)
}

/**
 * @param {any} params
 */
export function getUserAgentUsers(params) {
    return get("/user/agent/users", params)
}

/**
 * @param {any} body
 */
export function postUserTurnOut(body) {
    return post("/user/turnout", body)
}

/**
 * @param {any} data
 */
export function postUserDraw(data) {
    return post("/user/draw", data)
}


/**
 * @param {any} params
 */
export function getUserShopBasicInfo(params) {
    return get("/user/shop/basicinfo", params);
};

/**
 * @param {any} params
 */
export function getUserShopInfo(params) {
    return get("/user/shop/detail", params);
};

/**
* @param {any} params
*/
export function getUserPayPasswordExist(params) {
   return get("/user/paypassword/exist", params);
}

/**
 * @param {any} data
 */
export function postUserPayPasswordChange(data) {
    return post("/user/paypassword/change", data);
}

/**
 * @param {any} data
 */
export function postUserPasswordChange(data) {
    return post("/user/password/change", data);
}


/**
 * @param {any} data
 */
export function postUserFollowToggle(data) {
    return post("/user/follow/toggle", data);
}

/**
 * @param {any} data
 */
export function postUserUpdateAvatar(data) {
    return postForm("/user/avatar/upload", data)
}

/**
 * @param {any} params
 */
export function getUserDrawList(params) {
    return get('/user/draw/list', params)
}

/**
 * @param {any} params
 */
export function getSTSToken(params) {
    return get("/user/sts", params)
}