import React, { useEffect, useState, useMemo, useRef } from "react";
import { InfiniteScroll, List, NavBar, Tabs, Toast } from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import { getBetList } from "../../axios";
import { cnNameMap } from "../../constant/cnNameMap";
import "./BetListNew.css";
import { getTimeInMonth } from "../../utils";
import { PlayType } from "../../constant/betPlayType";
import { useSelector } from "react-redux";
const status2CN = {
    "0": "未接单",
    "1": "未出票",
    "2": "已出票",
    "3": "未中奖",
    "4": "已中奖",
    "5": "已派奖",
    "6": "已撤单",
    "7": "合买",
};

export default function BetList() {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const defaultActiveKey = useMemo(() => {
        return location.state?.tab || '0,1,2,3,4,5,6'
    }, [location.state?.tab]);
    const [scrollTop, setScrollTop] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [currentTab, setCurrentTab] = useState(defaultActiveKey);
    const [betList, setBetList] = useState<any>([]);
    const betListRef = useRef(null);
    const pagenation = useRef({ pageNum: 0, loading: false });
    const userId = useMemo(() => {
        return location.state?.id || user._id;
    }, [location.state?.id, user._id])

    async function handleClick(betId: string) {
        setScrollTop(betListRef.current?.scrollTop || 0);
        navigate(`/person/bet/detail/${betId}`);
    }
    useEffect(() => {
        if(location.pathname === "/person/bet/list") {
            if(betListRef) {
                setTimeout(() => {
                    betListRef.current.scrollTop = scrollTop
                }, 1)
            }
        }
    }, [location.pathname, scrollTop]);

    async function loadMore() {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        let query: any = {
            status: currentTab,
            pageNum: pagenation.current.pageNum,
            pageSize: 10,
        };
        pagenation.current.pageNum++;
        if (userId) {
            query["userId"] = userId;
        }
        try {
            const { data } = await getBetList(query);
            setBetList([...betList, ...data.betList]);
            setHasMore(data.betList.length === 10);
        } catch (e) {
            Toast.show("获取彩票失败");
        }
        pagenation.current.loading = false;
    }
    
    useEffect(() => {
        if(pagenation.current.loading) {
            return;
        }
        pagenation.current.loading = true;
        setBetList([]);
        pagenation.current.pageNum = 0;
        let query: any = {
            status: currentTab,
            pageNum: pagenation.current.pageNum++,
            pageSize: 10,
        };
        if (userId) {
            query["userId"] = userId;
        }
        getBetList(query).then(({ data }: any) => {
            setBetList(data.betList);
            setHasMore(data.betList.length === 10);
        }).finally(() => {
            pagenation.current.loading = false;
        })
    }, [currentTab, userId]);
    const back = () => {
        navigate("/person/person");
    }
    return (
        <div className="bet-list">
            <NavBar onBack={back}>
                投注记录
            </NavBar>
            <Tabs onChange={(val) => setCurrentTab(val)} defaultActiveKey={defaultActiveKey}>
                <Tabs.Tab title='全部' key='0,1,2,3,4,5,6' />
                <Tabs.Tab title='待出票' key='0,1' />
                <Tabs.Tab title='待开奖' key='2' />
                <Tabs.Tab title='已开奖' key='3,4,5' />
                <Tabs.Tab title='已中奖' key='4,5' />
            </Tabs>
            <div style={{ flex: "1 1", overflow: "auto" }}  ref={betListRef}>
                <List>
                    {betList && betList.map((bet: any, index: number) => {
                        const {playType, lastCloseTime, userBuyTime} = bet;
                        const isCrossDay = new Date(lastCloseTime).getDay() !== new Date(userBuyTime).getDay();
                        return (
                            <List.Item key={index}>
                                <div className="centerBox" onClick={() => handleClick(bet._id)}>
                                    <div className="left">
                                        <div className="t1">
                                            <span style={{ fontWeight: 600 }}>{ cnNameMap[bet.type] }</span>
                                            <span style={{ fontSize: "12px", color: "rgb(153, 153, 153)" }}>
                                                {
                                                    playType === PlayType.GroupBuy && "(合买)"
                                                }
                                                {
                                                    playType === PlayType.PublishFollowBuy && "(发单)"
                                                }
                                                {
                                                    playType === PlayType.FollowBuy && "(跟单)"
                                                }
                                                {
                                                    playType === PlayType.Normal && "(自购)"
                                                }
                                                {
                                                    playType === PlayType.ChaseNum && "(追号)"
                                                }
                                                {
                                                    playType === PlayType.BonusOptimization && "(奖金优化)"
                                                }
                                            </span>
                                        </div>
                                        <div className="t2">{cnNameMap[bet.subType]}</div>
                                    </div>
                                    <div className="center">
                                        <div className="t1" style={{ color: "red" }}>{ bet.spend }元</div>
                                        { bet.remark && <div style={{ fontSize: "12px" }}>备注：{bet.remark}</div>}
                                    </div>
                                    <div className="right">
                                        {
                                            ["4", "5"].includes(bet.status) && (
                                                <div className="t1" style={{ fontWeight: 900 }}>
                                                    <span>中奖{bet.bonus?.toFixed(2)}元</span>
                                                </div>
                                            )
                                        }
                                        {
                                            !["4", "5"].includes(bet.status) && (
                                                <div className="t3" style={{ fontWeight: 900 }}>
                                                    <span>{status2CN[bet.status]}</span>
                                                </div>
                                            )
                                        }
                                        <div style={{ fontSize: "12px", color: "rgb(153, 153, 153)", marginTop: "5px" }}>
                                            { getTimeInMonth(bet.userBuyTime) }
                                            { isCrossDay && <span style={{ color: "red" }}>【隔】</span> }</div>
                                    </div>
                                </div>
                            </List.Item>
                        )
                    })}
                </List>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
            </div>
        </div>
    )
}